<template>
  <div>
    <v-card flat>
      <v-toolbar flat class="mt-4">
        <v-select
          label="Select Year"
          v-model="selectedYear"
          dense
          :items="filteredYears"
          style="max-width: 220px"
          class="mt-6"
          @change="leaveReport()"
          outlined
        ></v-select>
        <v-text-field
          style="max-width: 220px"
          label="Search"
          v-model="search"
          class="mt-5 ml-2"
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :headers="tabledata"
          :items="exporttable"
          :items-per-page="10"
          :fixed-header="fixed"
          :loading="isLoadingesa"
          :search="search"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
          @click:row="handleClick"
        >
          <template v-slot:[`item.contact_number`]="{ item }">
            <span>{{
              item.contact_number == undefined ? "-" : item.contact_number
            }}</span>
          </template>
          <template v-slot:[`item.applied_leave_count`]="{ item }">
            <span><a><u>{{ item.applied_leave_count }}</u></a></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentCheck == 1">
      <LeaveView
        :leavedetails="leavedetails"
        @clicked="leavedetails = false"
        :leaveitem="leaveitem"
      />
    </div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
  
  <script>
import LeaveView from "@/components/Dialogs/LeaveView.vue";
import { API, graphqlOperation } from "aws-amplify";
import { LeaveReportForMember } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
export default {
  components: {
    SnackBar,
    LeaveView,
  },
  data() {
    return {
      search: "",
      leavedetails: false,
      selectedYear: new Date().getFullYear(),
      componentCheck: 0,
      tabledata: [
        {
          text: "Member Id",
          value: "member_id",
          fixed: true,
          sortable: false,
        },
        { text: "Members", fixed: true, value: "user_name" },
        {
          text: "Contact Number",
          value: "contact_number",
          fixed: true,
          sortable: false,
        },
        {
          text: "Leave Count",
          value: "applied_leave_count",
          fixed: true,
          sortable: false,
        },
      ],
      appitems: [
        { workshift_name: "My Prezence (User)", workshift_id: "MY_PREZENCE" },
        { workshift_name: "Prezence (Admin)", workshift_id: "PREZENCE_ADMIN" },
      ],
      isLoadingesa: false,
      height: 0,
      exporttable: [],
      SnackBarComponent: {},
      fixed: true,
      selectedDate: "",
    };
  },
  computed: {
    filteredYears() {
      const currentYear = new Date().getFullYear();
      // const selectedDateYear = new Date(this.selectedDate).getFullYear();
      var dateComponents = this.selectedDate.split(/[/,:\s]+/);

      var month = parseInt(dateComponents[1], 10) - 1;

      var date = new Date(
        dateComponents[2],
        month,
        dateComponents[0],
        dateComponents[3],
        dateComponents[4],
        dateComponents[5]
      );
      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
  },
  watch: {
    search() {
      if (this.search != "") {
        if (this.search.length >= 3) {
          this.leaveReport();
        }
      } else {
        this.leaveReport();
      }
    },
  },
  created() {
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    this.height = window.innerHeight - 210;
    this.leaveReport();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    handleClick(val) {
      this.componentCheck = 1;
      this.leaveitem = val;
      this.leavedetails = true;

      console.log(val, "val");
    },
    async leaveReport() {
      this.exporttable = [];
      this.isLoadingesa = true;
      try {
        let result = await API.graphql(
          graphqlOperation(LeaveReportForMember, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              leave_year: this.selectedYear,
              user_name: this.search,
            },
          })
        );
        var respnse = JSON.parse(result.data.LeaveReportForMember);

        if (respnse.Status == "SUCCESS") {
          this.exporttable = [];
          this.exporttable = respnse.data;
          // console.log(this.exporttable);
          this.isLoadingesa = false;
        }

        this.isLoadingesa = false;
        // window.open(item);
      } catch (error) {
        console.log(error);
        this.isLoadingesa = false;
        this.overlay = false;
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>
  
  <style>
</style>