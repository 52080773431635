<template>
  <div>
    <v-card flat>
      <v-toolbar flat class="mt-4">
        <v-select
          v-model="apptype"
          label="App Type"
          dense
          outlined
          :items="appitems"
          item-text="workshift_name"
          item-value="workshift_id"
          class="mt-5"
          style="max-width: 220px"
          @change="esa_report()"
        ></v-select>
        <v-select
          v-model="appinstall"
          label="App Installed"
          dense
          outlined
          :items="appinstallitems"
          item-text="workshift_name"
          item-value="workshift_id"
          class="mt-5 ml-2"
          style="max-width: 220px"
          @change="esa_report()"
        ></v-select>
        <v-text-field
          style="max-width: 220px"
          label="Search"
          v-model="search"
          class="mt-5 ml-2"
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :headers="apptype == 'MY_PREZENCE' ? tabledata : Intabledata"
          :items="exporttable"
          :items-per-page="10"
          :fixed-header="fixed"
          :loading="isLoadingesa"
          :search="search"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.face_s3_key`]="{ item }">
            <!-- <a @click="view_profile(item)"> -->
            <v-avatar size="36" class="ma-1" v-if="item.face_s3_key != null">
              <v-img
                :src="
                  'https://przncemembersprofile.s3.ap-south-1.amazonaws.com/' +
                  item.face_s3_key
                "
              >
              </v-img>
            </v-avatar>
            <v-avatar size="45" v-else>
              <v-icon color="primary" large dark> mdi-account-circle </v-icon>
            </v-avatar>
            <!-- </a> -->
          </template>
          <template v-slot:[`item.Last_Login`]="{ item }">
            <span
              v-if="apptype == 'MY_PREZENCE'"
              v-text="
                item.member_app_last_login == undefined
                  ? '-'
                  : get_date(item.member_app_last_login)
              "
            ></span>
            <span v-else>{{
              item.admin_app_last_login == undefined
                ? "-"
                : get_date(item.admin_app_last_login)
            }}</span>
          </template>

          <template v-slot:[`item.app_device`]="{ item }">
            <span v-if="apptype == 'MY_PREZENCE'">
              <v-icon>{{
                item.member_device_type === "IOS"
                  ? "mdi-apple"
                  : item.member_device_type === "ANDROID"
                  ? "mdi-android"
                  : ""
              }}</v-icon>
              {{
                item.member_device_type == null ||
                item.member_device_type == undefined
                  ? "-"
                  : ""
              }}
              {{
                item.member_app_version != null ||
                item.member_app_version != undefined
                  ? item.member_app_version
                  : "-"
              }}
            </span>
            <span v-else>
              <v-icon>{{
                item.admin_device_type === "IOS"
                  ? "mdi-apple"
                  : item.admin_device_type === "ANDROID"
                  ? "mdi-android"
                  : ""
              }}</v-icon>
              {{
                item.admin_device_type == null ||
                item.admin_device_type == undefined
                  ? "-"
                  : ""
              }}
              {{
                item.admin_app_version != null ||
                item.admin_app_version != undefined
                  ? item.admin_app_version
                  : "-"
              }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { app_install_users_list } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      search: "",
      Intabledata: [
        {
          text: "Member Profile",
          value: "face_s3_key",
          fixed: true,
          sortable: false,
          width: "50px",
        },
        {
          text: "Member Id",
          value: "employee_id",
          fixed: true,
          sortable: false,
        },
        { text: "Members", fixed: true, value: "user_name" ,sortable: false,},
        {
          text: "Email ID",
          value: "user_email_id",
          fixed: true,
          sortable: false,
        },
        {
          text: "Last Login",
          value: "Last_Login",
          fixed: true,
          sortable: true,
        
        },
        { text: "Device / App Version", value: "app_device", sortable: false },
      ],
      tabledata: [
        {
          text: "Member Profile",
          value: "face_s3_key",
          fixed: true,
          sortable: false,
          width: "50px",
        },
        {
          text: "Member Id",
          value: "employee_id",
          fixed: true,
          sortable: false,
        },
        { text: "Members", fixed: true, value: "user_name" },
        {
          text: "Contact Number",
          value: "user_full_contact_number",
          fixed: true,
          sortable: false,
        },
        {
          text: "Last Login",
          value: "Last_Login",
          fixed: true,
          sortable: false,
        },
        { text: "Device / App Version", value: "app_device", sortable: false },
      ],
      appitems: [
        { workshift_name: "My Prezence (User)", workshift_id: "MY_PREZENCE" },
        { workshift_name: "Prezence (Admin)", workshift_id: "PREZENCE_ADMIN" },
      ],
      isLoadingesa: false,
      height: 0,
      exporttable: [],
      SnackBarComponent: {},
      fixed: true,
      apptype: "MY_PREZENCE",
      appinstall: "Installed",
      appinstallitems: [
        { workshift_name: "Installed", workshift_id: "Installed" },
        { workshift_name: "Not Installed", workshift_id: "Not_Installed" },
      ],
    };
  },
  created() {
    this.height = window.innerHeight - 210;
    this.esa_report();
  },
  methods: {
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date ).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    async esa_report() {
      this.exporttable = [];
      this.isLoadingesa = true;
      try {
        let result = await API.graphql(
          graphqlOperation(app_install_users_list, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: this.apptype,
              is_install_app: this.appinstall == "Installed" ? true : false,
            },
          })
        );
        var respnse = JSON.parse(result.data.app_install_users_list);

        if (respnse.Status == "SUCCESS") {
          this.exporttable = [];
          this.exporttable = respnse.data;
          // console.log(this.exporttable);
          this.isLoadingesa = false;
        }

        this.isLoadingesa = false;
        // window.open(item);
      } catch (error) {
        console.log(error);
        this.isLoadingesa = false;
        this.overlay = false;
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>