<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card flat>
      <v-toolbar flat>
        <v-card-title
          ><strong><h2>Early Salary Access</h2></strong></v-card-title
        >
        <v-switch
          v-model="DataItems.is_early_salary_enabled"
          dense
          inset
          class="mt-6"
          @click="send_details()"
        >
        </v-switch>
      </v-toolbar>
      <v-card-subtitle class="ml-4 mt-n4 mb-2"
        ><strong
          >(Enable, if you wish to grant employees access a portion of their
          earned salary at any time before their payday)</strong
        ></v-card-subtitle
      >
      <v-card-text v-show="DataItems.is_early_salary_enabled == true">
        <v-row class="ml-2">
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-card
              class="pl-2 pr-3 elevation-1"
              :disabled="DataItems.is_early_salary_enabled == false"
            >
              <v-row class="ml-1">
                <v-col>
                  <label class="font-weight-medium">Pay Type:</label>
                  <v-select
                    v-model="Duration"
                    dense
                    outlined
                    :items="duration_item"
                    style="max-width: 170px"
                    class="mt-1 ml-n1"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <label class="font-weight-medium">Earned Rate:</label>
                  <v-text-field
                    :value="hourly_earning_rate[0]"
                    @change="$set(hourly_earning_rate, 0, $event)"
                    :max="max2"
                    :min="min2"
                    v-model="hourly_earning_rate"
                    prepend-inner-icon="mdi-currency-inr"
                    type="number"
                    style="max-width: 170px"
                    dense
                    :rules="billing_amount_rule"
                    outlined
                    class="mt-1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-n12">
                <v-row class="mt-1">
                  <v-col>
                    <label class="font-weight-medium">Min Amount:</label>
                    <v-select
                      dense
                      outlined
                      style="max-width: 170px"
                      class="mt-1"
                      :items="minamount"
                      label="Min"
                      prepend-inner-icon="mdi-currency-inr"
                      v-model="DataItems.min_ammount_per_trans"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <label class="ml-2 font-weight-medium">Max Request:</label>
                    <v-select
                      v-model="DataItems.max_ammount_per_trans"
                      dense
                      outlined
                      type="number"
                      :items="maxamount"
                      label="Max"
                      prepend-inner-icon="mdi-currency-inr"
                      style="max-width: 170px"
                      class="ml-2 mt-1"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-n11">
                <v-row class="mt-1">
                  <v-col>
                    <label class="font-weight-medium">Max No Of Request:</label>
                    <v-select
                      dense
                      outlined
                      :items="request"
                      style="max-width: 170px"
                      class="mt-1"
                      v-model="DataItems.max_request_per_user"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <label class="ml-2 font-weight-medium"
                      >Service Fee Request:</label
                    >

                    <v-text-field
                      :value="service_fee_per_trans[0]"
                      @change="$set(service_fee_per_trans, 0, $event)"
                      dense
                      outlined
                      :max="max"
                      :min="min"
                      style="max-width: 170px"
                      :items="servicecharge"
                      prepend-inner-icon="mdi-currency-inr"
                      v-model="service_fee_per_trans"
                      type="number"
                      class="ml-2 mt-1"
                      :rules="billing_amount_rule"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-n12">
                <v-row class="mt-1">
                  <v-col>
                    <label class="font-weight-medium"
                      >Max Amount Per Month:</label
                    >
                    <v-select
                      dense
                      outlined
                      style="max-width: 170px"
                      class="mt-1"
                      prepend-inner-icon="mdi-currency-inr"
                      :items="maxamount1"
                      v-model="DataItems.total_ammount_monthly_max_request"
                      type="number"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <label class="font-weight-medium ml-2"
                      >Select Approver:</label
                    >
                    <v-select
                      dense
                      outlined
                      style="max-width: 170px"
                      class="ml-2 mt-1"
                      :items="searchAdmin"
                      item-text="text1"
                      :rules="[(v) => !!v || 'Required']"
                      item-value="value2"
                      v-model="DataItems.approver_id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>

              <!--                 
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-n10">
                  <label class="font-weight-medium ">Reported Date:</label>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    color="#f4813f"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="DataItems.report_date"
                        dense
                        style="max-width: 170px"
                        outlined
                        class="mr-2 mt-1"
                        persistent-hint
                        :disabled="isLoading == true"
                        color="#f4813f"
                        v-bind="attrs"
                        :rules="rules"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      :max="
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          1
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      :min="
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          2
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      v-model="date"
                      color="#f4813f"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="#f4813f"
                        @click="(modal = false), (button_condition = false)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        v-model="button_condition"
                        text
                        color="#f4813f"
                        @click="
                          $refs.dialog.save(date), (button_condition = true)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col> -->
              <v-row>
                <v-spacer />
                <v-btn
                  @click="validateform()"
                  small
                  class="gradient-bg white--text mt-n2 mr-6 mb-3"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>save Details
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-card class="pl-2 pr-3 elevation-1 mr-n5 mt-n2">
              <v-card-title class="pt-2"
                >Note:

                <v-card-text>
                  <li>
                    Hourly /daily rate at which you would consider early
                    salary<br />
                    (calculated on a pro rata basis)
                  </li>
                  <li class="pageBreak">
                    Set the Minimum and maximum amount that an employee can
                    request<br />
                    (setting limits for each request)
                  </li>
                  <li>
                    Number of times an individual can avail facility<br />
                    (number of requests in a month)
                  </li>
                  <li>
                    Amount which is the service fee<br />
                    (fixed fee is charged for every request)
                  </li>
                  <li>
                    Amount which is the maximum limit for a month<br />
                    (total amount in a month)
                  </li>

                  <li class="pageBreak1 pb-4">
                    The final amount calculation <br />(Number of worked
                    days/hours x rate - Service Fee = Final Amount)<br />
                    Ex. 10 Days x INR 500 - INR 250 = INR 4750
                  </li>
                </v-card-text>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { getEarlySalaryEditAndList } from "@/graphql/mutations.js";
import { ListAllUsers } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/SnackBar.vue";

export default {
  components: {
    Snackbar,
    Overlay,
  },
  data: () => ({
    service_fee_per_trans: 20,
    month_max: "2023-03-31",
    mount_min: "2023-03",
    max: 100,
    min: 0,
    start: false,
    billing_amount_rule: [
      (value) => /^\d+$/.test(value) || "Only numbers are allowed",
    ],
    max2: 100,
    isLoading: false,
    rules: [(v) => !!v || "required"],
    min2: 10,
    startDate: new Date(new Date().getFullYear(), "0", "01"),
    endDate: new Date(new Date().getFullYear() + 1, "11", "31"),
    SnackBarComponent: {},
    searchAdmin: [],
    is_early_salary_enabled: false,
    min_ammount_per_trans: 2000,
    maxamount: [3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
    maxamount1: [5000, 6000, 7000, 8000, 9000, 10000],
    minamount: [500, 1000, 1500, 2000],
    request: [1, 2, 3],
    servicecharge: [],
    total_ammount_monthly_max_request: 5000,
    max_request_per_user: 2,
    max_ammount_per_trans: 5000,
    approver_id: "",
    hourly_earning_rate: 10,
    overlay: false,
    is_daily: "Hourly",
    DataItems: [],
    Duration: "",
    duration_item: ["Hourly", "Daily"],
    modal: false,
    date: new Date().toISOString().substr(0, 10),
    button_condition: false,
  }),
  created() {
    this.overlay = true;
    // this.form();
    // this.get_datails();
  },
  mounted() {
    this.get_datails();
    this.maillist();
  },
  // computed: {
  //   report_date() {
  //     return this.formatDate(this.date);
  //   },
  // },
  watch: {
    modal(val) {
      if (val == false && this.button_condition == true) {
        this.DataItems.report_date = this.report_date;
      }
    },
    is_early_salary_enabled: {
      handler() {
        this.report_date = this.DataItems.report_date;
      },

      immediate: true,
    },
  },
  methods: {
    validateform() {
      if (this.DataItems.approver_id != "N/A") {
        this.send_details();
        setTimeout(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Updated successfully",
          };
        }, 500);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter all the fields",
        };
      }
    },
    async send_details() {
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(getEarlySalaryEditAndList, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "EDIT",
              total_ammount_monthly_max_request:
                this.DataItems.total_ammount_monthly_max_request,
              is_daily: this.Duration == "Hourly" ? false : true,
              is_early_salary_enabled:
                this.DataItems.is_early_salary_enabled == true ? 1 : 0,
              hourly_earning_rate: this.hourly_earning_rate,
              max_ammount_per_trans: this.DataItems.max_ammount_per_trans,
              min_ammount_per_trans: this.DataItems.min_ammount_per_trans,
              max_request_per_user: this.DataItems.max_request_per_user,
              service_fee_per_trans: this.service_fee_per_trans,
              approver_id: this.DataItems.approver_id,
              report_date: this.DataItems.report_date,
            },
          })
        );
        if (
          this.DataItems.is_early_salary_enabled == true &&
          this.DataItems.approver_id == "N/A"
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText:
              "If you want to use Early Salary Access please select the Approver ",
          };
        } else if (this.DataItems.is_early_salary_enabled == true) {
          // console.log(this.DataItems.approver_id,'ss')
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Early Salary Access Enabled",
          };
        } else if (this.DataItems.is_early_salary_enabled == false) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Early Salary Access Disabled",
          };
        }

        this.overlay = false;
        this.get_datails();
        // console.log(result);
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        console.log(error);
        this.overlay = false;
      }
    },
    async get_datails() {
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(getEarlySalaryEditAndList, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "LIST",
              total_ammount_monthly_max_request:
                this.DataItems.total_ammount_monthly_max_request,
              is_daily: this.Duration == "Hourly" ? false : true,
              is_early_salary_enabled:
                this.DataItems.is_early_salary_enabled == true ? 1 : 0,
              hourly_earning_rate: this.DataItems.hourly_earning_rate,
              max_ammount_per_trans: this.DataItems.max_ammount_per_trans,
              min_ammount_per_trans: this.DataItems.min_ammount_per_trans,
              max_request_per_user: this.DataItems.max_request_per_user,
              service_fee_per_trans: this.service_fee_per_trans,
              approver_id: this.DataItems.approver_id,
              report_date: this.DataItems.report_date,
            },
          })
        ).then((res) => {
          // console.log(res);
          this.DataItems = JSON.parse(res.data.getEarlySalaryEditAndList).data;
          this.hourly_earning_rate = this.DataItems.hourly_earning_rate;
          this.service_fee_per_trans = this.DataItems.service_fee_per_trans;
          //  console.log(this.DataItems.hourly_earning_rate)
          this.Duration = !this.DataItems.is_daily ? "Hourly" : "Daily";
          // console.log(res.data.getEarlySalaryEditAndList);
          // console.log(this.DataItems.report_date);
          // console.log(this.Duration);
        });

        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.overlay = false;
      }
    },
    async maillist() {
      try {
        let result1 = await API.graphql(
          graphqlOperation(ListAllUsers, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );

        this.mailList = result1.data.ListAllUsers.Admins;

        var mail = this;
        mail.mailList.forEach(function (item1) {
          mail.searchAdmin.push({
            text1: item1.user_email_id,
            value1: item1.user_email_id,
            value2: item1.user_id,
          });
        });
      } catch (err) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        console.log("err", err);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    form() {
      var date1 = new Date();
      var firstDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        2
      );
      var lastDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      );
      // console.log(firstDay, "first");
      // console.log(lastDay, "last");
      return `${date1} + ${firstDay} + ${lastDay}`;
    },
  },
};
</script>

<style>
.pageBreak {
  word-spacing: 1px;
  /* page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  position:relative; */
}
.pageBreak1 {
  word-spacing: 3px;
}
</style>