var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-select',{staticClass:"mt-5",staticStyle:{"max-width":"220px"},attrs:{"label":"App Type","dense":"","outlined":"","items":_vm.appitems,"item-text":"workshift_name","item-value":"workshift_id"},on:{"change":function($event){return _vm.esa_report()}},model:{value:(_vm.apptype),callback:function ($$v) {_vm.apptype=$$v},expression:"apptype"}}),_c('v-select',{staticClass:"mt-5 ml-2",staticStyle:{"max-width":"220px"},attrs:{"label":"App Installed","dense":"","outlined":"","items":_vm.appinstallitems,"item-text":"workshift_name","item-value":"workshift_id"},on:{"change":function($event){return _vm.esa_report()}},model:{value:(_vm.appinstall),callback:function ($$v) {_vm.appinstall=$$v},expression:"appinstall"}}),_c('v-text-field',{staticClass:"mt-5 ml-2",staticStyle:{"max-width":"220px"},attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n5",attrs:{"height":_vm.height,"dense":"","headers":_vm.apptype == 'MY_PREZENCE' ? _vm.tabledata : _vm.Intabledata,"items":_vm.exporttable,"items-per-page":10,"fixed-header":_vm.fixed,"loading":_vm.isLoadingesa,"search":_vm.search,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"item.face_s3_key",fn:function(ref){
        var item = ref.item;
return [(item.face_s3_key != null)?_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":'https://przncemembersprofile.s3.ap-south-1.amazonaws.com/' +
                item.face_s3_key}})],1):_c('v-avatar',{attrs:{"size":"45"}},[_c('v-icon',{attrs:{"color":"primary","large":"","dark":""}},[_vm._v(" mdi-account-circle ")])],1)]}},{key:"item.Last_Login",fn:function(ref){
                var item = ref.item;
return [(_vm.apptype == 'MY_PREZENCE')?_c('span',{domProps:{"textContent":_vm._s(
              item.member_app_last_login == undefined
                ? '-'
                : _vm.get_date(item.member_app_last_login)
            )}}):_c('span',[_vm._v(_vm._s(item.admin_app_last_login == undefined ? "-" : _vm.get_date(item.admin_app_last_login)))])]}},{key:"item.app_device",fn:function(ref){
            var item = ref.item;
return [(_vm.apptype == 'MY_PREZENCE')?_c('span',[_c('v-icon',[_vm._v(_vm._s(item.member_device_type === "IOS" ? "mdi-apple" : item.member_device_type === "ANDROID" ? "mdi-android" : ""))]),_vm._v(" "+_vm._s(item.member_device_type == null || item.member_device_type == undefined ? "-" : "")+" "+_vm._s(item.member_app_version != null || item.member_app_version != undefined ? item.member_app_version : "-")+" ")],1):_c('span',[_c('v-icon',[_vm._v(_vm._s(item.admin_device_type === "IOS" ? "mdi-apple" : item.admin_device_type === "ANDROID" ? "mdi-android" : ""))]),_vm._v(" "+_vm._s(item.admin_device_type == null || item.admin_device_type == undefined ? "-" : "")+" "+_vm._s(item.admin_app_version != null || item.admin_app_version != undefined ? item.admin_app_version : "-")+" ")],1)]}}],null,true)})],1)],1),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }