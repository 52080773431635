<template>
  <div>
    <v-card flat class="mt-5 overflow-hidden">
      <v-row>
        <v-col
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <!-- <v-spacer v-if="$vuetify.breakpoint.name != 'xs'"></v-spacer> -->
            <v-select
              label="Select Year"
              v-model="selectedYear"
              dense
              :items="filteredYears"
              style="max-width: 220px"
              class="mt-6"
              @change="get_holiday_list()"
              outlined
            ></v-select>
            <v-text-field
              v-model="search"
              label="Search"
              dense
              style="max-width: 220px"
              append-icon="mdi-magnify"
              class="mt-5 ml-2"
            ></v-text-field>
            <v-spacer />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="addHolidayDialog = true"
                  small
                  v-on="on"
                  class="gradient-bg white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Holiday</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-select
              label="Select Year"
              v-model="selectedYear"
              dense
              :items="filteredYears"
              class="mt-6"
              @change="get_holiday_list()"
              outlined
            ></v-select>
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              class="mt-6 mr-2 ml-3"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="addHolidayDialog = true"
                  small
                  v-on="on"
                  class="gradient-bg white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Holiday</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="HolidayHeaders"
          dense
          :height="height"
          :search="search"
          :items="HolidayItems"
          :items-per-page="50"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.holiday_date`]="{ item }">
            <span>{{ call_convert_date(item) }}</span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="delete_holiday_list(item)"
                  color="primary"
                  v-on="on"
                  v-if="item"
                  small
                  >mdi-delete</v-icon
                >
              </template>
              <span class="white--text">Delete this Holiday?</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Holiday List created yet.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AddHoliday
      :addHolidayDialog="addHolidayDialog"
      @close_add_holiday_dialog="get_holiday_lists"
    />
    <Overlay :overlay="overlay" />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import AddHoliday from "@/components/Dialogs/AddHoliday.vue";
import { API, graphqlOperation } from "aws-amplify";
import { holidayFilterByYear } from "@/graphql/queries.js";
import { DeleteHoliday } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

import Overlay from "@/components/Overlay.vue";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";

export default {
  components: {
    AddHoliday,
    Overlay,
    DeleteDialog,
    SnackBar,
  },
  data: () => ({
    SnackBarComponent: {},
    height: 0,
    deleteLoading: false,
    confDeleteDialog: false,
    deleteColor: "",
    deleteIcon: "",
    deleteText: "",
    addHolidayDialog: false,
    isLoading: false,
    overlay: false,
    selectedYear: new Date().getFullYear(), // Set the current year as the initial value
    yearlist_items: [],
    search: "",
    filtermonths: [
      { month: "Jan", monthvalue: "01" },
      { month: "Feb", monthvalue: "02" },
      { month: "Mar", monthvalue: "03" },
      { month: "Apr", monthvalue: "04" },
      { month: "May", monthvalue: "05" },
      { month: "Jun", monthvalue: "06" },
      { month: "Jul", monthvalue: "07" },
      { month: "Aug", monthvalue: "08" },
      { month: "Sep", monthvalue: "09" },
      { month: "Oct", monthvalue: "10" },
      { month: "Nov", monthvalue: "11" },
      { month: "Dec", monthvalue: "12" },
    ],
    HolidayHeaders: [
      { text: "Date", value: "holiday_date" },
      { text: "Day", value: "holiday_day" },
      { text: "Occassion", value: "occasion_type" },
      { text: "Actions", value: "Actions" },
    ],
    HolidayItems: [],
    selectedDate: "",
  }),
  created() {
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    this.overlay = true;
    this.isLoading = true;
    this.height = window.innerHeight - 210;
    this.get_holiday_list();
  },
  computed: {
    filteredYears() {
      const currentYear = new Date().getFullYear()+1;
      // const selectedDateYear = new Date(this.selectedDate).getFullYear();
      var dateComponents = this.selectedDate.split(/[/,:\s]+/);

      var month = parseInt(dateComponents[1], 10) - 1;

      var date = new Date(
        dateComponents[2],
        month,
        dateComponents[0],
        dateComponents[3],
        dateComponents[4],
        dateComponents[5]
      );
      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
  },
  methods: {
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    call_convert_date(item) {
      let a = this.filtermonths.filter(
        (val) => item.holiday_date.split("-")[1] == val.monthvalue
      )[0].month;
      return (
        item.holiday_date.split("-")[0] +
        "-" +
        a +
        "-" +
        item.holiday_date.split("-")[2]
      );
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.delete_holiday(this.deleteUserItem);
    },
    delete_holiday_list(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText = `Are you sure you want to Delete the Holiday ${item.occasion_type} ?`;
      this.deleteIcon = "mdi-delete";
      this.deleteColor = "red";
    },
    async delete_holiday(item) {
      try {
        await API.graphql(
          graphqlOperation(DeleteHoliday, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              holiday_date: item.holiday_date,
            },
          })
        ).then((res) => {
          this.deleteLoading = false;
          this.get_holiday_list(this.year);
          this.confDeleteDialog = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.DeleteHoliday).Message,
          };
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.deleteLoading = false;
      }
    },
    get_holiday_lists() {
      this.addHolidayDialog = false;
      this.get_holiday_list();
    },

    async get_holiday_list() {
      this.overlay = true;
      this.isLoading = true;

      try {
        await API.graphql(
          graphqlOperation(holidayFilterByYear, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              year: this.selectedYear,
            },
          })
        ).then((res) => {
          // console.log("ers", JSON.parse(res.data.holidayFilterByYear).data);
          this.HolidayItems = JSON.parse(res.data.holidayFilterByYear).data;
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log(err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
