import Router from "vue-router";
import Vue from "vue";
import Home from "../views/ChildHomePages/Home.vue";
// import Login from "../views/MainHomePages/Login.vue";
// import Signup from "../views/MainHomePages/Signup.vue";
import forgotPassword from "../views/MainHomePages/forgotPassword.vue";
import EnrollOrganization from "../views/MainHomePages/EnrollOrganization.vue";
import LoginRecharge from "@/views/MainHomePages/LoginRecharge.vue";
import ReportIssue from "@/components/ReportIssue.vue";
import NewLogin from "@/views/MainHomePages/NewLogin.vue";
import NewSignup from "@/views/MainHomePages/NewSignup.vue";
Vue.use(Router);
export default new Router({
    routes: [
        //     {
        //     path: "/",
        //     name: "Login",
        //     component: Login,
        // },
        {
            path: "/",
            name: "Login",
            component: NewLogin,

        },
        {
            path: "/Signup",
            name: "NewSignup",
            component: NewSignup,

        },

        {
            path: "/LoginRecharge",
            name: "LoginRecharge",
            component: LoginRecharge,

        },
        {
            path: "/forgotPassword",
            name: "forgotPassword",
            component: forgotPassword,
        },
        // {
        //     path: "/Signup",
        //     name: "Signup",
        //     component: Signup,
        // },
        {
            path: "/EnrollOrganization",
            name: "EnrollOrganization",
            component: EnrollOrganization,
        },
        {
            path: "/ReportIssue",
            name: "ReportIssue",
            component: ReportIssue,
        },
        {
            component: Home,
            path: "/home",
            children: [{
                path: "/Dashboard",
                name: "Dashboard",
                component: () => import("../views/ChildHomePages/Dashboard.vue")
            },
            {
                path: "/Members",
                name: "Members",
                component: () => import("../views/ChildHomePages/Members.vue"),
            },
            {
                path: "/Locations",
                name: "Locations",
                component: () => import("../views/ChildHomePages/Locations.vue"),
            },
            {
                path: "/DailyPresentReport",
                name: "DailyPresentReport",
                component: () => import("../views/ChildHomePages/Reports/DailyReports/DailyPresentReport.vue"),
            },
            {
                path: "/DailyAbsentReport",
                name: "DailyAbsentReport",
                component: () => import("../views/ChildHomePages/Reports/DailyReports/DailyAbsentReport.vue"),
            },
            {
                path: "/DailyLateReport",
                name: "DailyLateReport",
                component: () => import("../views/ChildHomePages/Reports/DailyReports/DailyLateReport.vue"),
            },
            {
                path: "/DailyProofWork",
                name: "DailyProofWork",
                component: () => import("../views/ChildHomePages/Reports/DailyReports/DailyProofWork.vue"),
            },
            {
                path: "/DailyProxy",
                name: "DailyProxy",
                component: () => import("../views/ChildHomePages/Reports/DailyReports/DailyProxy.vue"),
            },
            {
                path: "/SwipeDataReport",
                name: "SwipeDataReport",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/SwipeDataReport.vue"),
            },
            {
                path: "/SwipeCountReport",
                name: "SwipeCountReport",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/SwipeCountReport.vue"),
            },
            {
                path: "/NormalizedReports",
                name: "NormalizedReports",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/NormalizedReports.vue"),
            },
            {
                path: "/LateArrivalReport",
                name: "LateArrivalReport",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/LateArrivalReport.vue"),
            },
            {
                path: "/EarlyGoers",
                name: "EarlyGoers",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/EarlyGoersReport.vue"),
            },
            {
                path: "/ShortDurationReport",
                name: "ShortDurationReport",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/ShortDurationReport.vue"),
            },
            {
                path: "/PresentReport",
                name: "PresentReport",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/PresentReport.vue"),
            },
            {
                path: "/WorkingHoliday",
                name: "WorkingHoliday",
                component: () => import("../views/ChildHomePages/Reports/MonthlyReports/WorkingHoliday.vue"),
            },
            {
                path: "/ExportReports",
                name: "ExportReports",
                component: () => import("../views/ChildHomePages/Reports/ExportReports.vue"),
            },
            {
                path: "/ScanLogs",
                name: "ScanLogs",
                // ../views/ChildHomePages/ScanLogs.vue

                component: () => import("@/components/Scanlogs/ScanlogsMembers.vue"),
            },
            {
                path: "/EsaTransaction",
                name: "EsaTransaction",
                component: () => import("../views/ChildHomePages/EsaTransaction.vue"),
            },
            {
                path: "/DataCorrection",
                name: "DataCorrection",
                component: () => import("../views/ChildHomePages/DataCorrection.vue"),
            },
            {
                path: "/Administrators",
                name: "Administrators",
                component: () => import("../views/ChildHomePages/Administrator.vue"),
            },
            {
                path: "/Transactions",
                name: "Transactions",
                component: () => import("../views/ChildHomePages/Transactions.vue"),
            },
            {
                path: "/AuditLogs",
                name: "AuditLogs",
                component: () => import("../views/ChildHomePages/AuditLogs.vue"),
            },
            {
                path: "/EsA",
                name: "EsA",
                component: () => import("@/views/ChildHomePages/Settings/EsA.vue"),
            },
            {
                path: "/WorkTimingsN",
                name: "WorkTimingsN",
                component: () => import("../views/ChildHomePages/Settings/WorkTimingsN.vue"),
            },
            {
                path: "/HolidayList",
                name: "HolidayList",
                component: () => import("../views/ChildHomePages/Settings/HolidayList.vue"),
            },
            {
                path: "/OrganisationDetails",
                name: "OrganisationDetails",
                component: () => import("../views/ChildHomePages/Settings/OrganisationDetails.vue"),
            },
            {
                path: "/ScanSettings",
                name: "ScanSettings",
                component: () => import("../views/ChildHomePages/Settings/ScanSettings.vue"),
            },
            {
                path: "/ProofworkSettings",
                name: "ProofworkSettings",
                component: () => import("../views/ChildHomePages/Settings/ProofworkSettings.vue"),
            },
            {
                path: "/AdvanceSettings",
                name: "AdvanceSettings",
                component: () => import("../views/ChildHomePages/Settings/AdvanceSettings.vue"),
            },
            {
                path: "/LeaveReport",
                name: "LeaveReport",
                component: () => import("../views/ChildHomePages/LeaveReport.vue"),
            },
            {
                path: "/LeaveSettings",
                name: "LeaveSettings",
                component: () => import("../views/ChildHomePages/Settings/LeaveSettings.vue"),
            },
            {
                path: "/ProofOfWorkType",
                name: "ProofOfWorkType",
                component: () => import("../views/ChildHomePages/Settings/ProofOfWorkType.vue"),
            },
            {
                path: "/LeaveApprovellist",
                name: "LeaveApprovellist",
                component: () => import("../views/ChildHomePages/LeaveApprovellist.vue"),
            },
            {
                path: "/ProfilApprove",
                name: "ProfilApprove",
                component: () => import("../views/ChildHomePages/ProfilApprove.vue"),
            },
            {
                path: "/FaceScan",
                name: "FaceScan",
                component: () => import("../views/ChildHomePages/FaceScan.vue"),
            },
            {
                path: "/GroupList",
                name: "GroupList",
                component: () => import("../views/ChildHomePages/GroupList.vue"),
            },
            {
                path: "/DesginationList",
                name: "DesginationList",
                component: () => import("../views/ChildHomePages/Settings/DesginationList.vue"),
            },
            {
                path: "/EmployeetypeList",
                name: "EmployeetypeList",
                component: () => import("../views/ChildHomePages/Settings/EmployeetypeList.vue"),
            },
            {
                path: "/WeeklyData",
                name: "WeeklyData",
                component: () => import("../views/ChildHomePages/Reports/WeeklyReports/WeeklyData.vue"),
            },
            {
                path: "/AppUserReports",
                name: "AppUserReports",
                component: () => import("../views/ChildHomePages/Reports/AppUserReports.vue")
            },
            {
                path: "/ProofwrkReport",
                name: "ProofwrkReport",
                component: () => import("../views/ChildHomePages/Reports/ProofwrkReport.vue")
            },
            {
                path: "/DataCorrectionreport",
                name: "DataCorrectionreport",
                component: () => import("../views/ChildHomePages/Reports/DataCorrectionreport.vue")
            },
            {
                path: "/LiveTrackingReport",
                name: "LiveTrackingReport",
                component: () => import("../views/ChildHomePages/Reports/LiveTrackingReport.vue")
            },
            {
                path: "/LeavedataReport",
                name: "LeavedataReport",
                component: () => import("../views/ChildHomePages/Reports/LeaveReport.vue")
            },

            ],
        }
    ]
});