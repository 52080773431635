var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-autocomplete',{staticClass:"mt-6 mr-2",staticStyle:{"max-width":"200px"},attrs:{"label":"Filter by Location","dense":"","outlined":"","item-text":"location_name","item-value":"location_id","disabled":_vm.isLoading == true,"items":_vm.locationItems},on:{"change":function($event){return _vm.get_scanlogs_pagination()}},model:{value:(_vm.LocationData),callback:function ($$v) {_vm.LocationData=$$v},expression:"LocationData"}}),_c('v-dialog',{ref:"dialog1",attrs:{"color":"#f4813f","return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6 ml-2",staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","persistent-hint":"","disabled":_vm.isLoading == true,"label":"From Date","color":"#f4813f","append-icon":"mdi-calendar","readonly":""},on:{"click:append":function($event){_vm.date ? _vm.getcal() : _vm.getcal()}},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"min":_vm.minmonth,"max":new Date().toISOString().substr(0, 10),"color":"#f4813f","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":[function($event){return _vm.get_scanlogs_pagination()},function($event){return _vm.$refs.dialog1.save(_vm.date)}]}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"color":"#f4813f","return-value":_vm.todate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.todate=$event},"update:return-value":function($event){_vm.todate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6 ml-2",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.isLoading == true,"dense":"","outlined":"","persistent-hint":"","label":"To Date","color":"#f4813f","append-icon":"mdi-calendar","readonly":""},on:{"click:append":function($event){_vm.todate ? _vm.gettimecal() : _vm.gettimecal()}},model:{value:(_vm.dataFormatted),callback:function ($$v) {_vm.dataFormatted=$$v},expression:"dataFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmodal),callback:function ($$v) {_vm.endmodal=$$v},expression:"endmodal"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"min":_vm.date,"color":"#f4813f","scrollable":""},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":function($event){_vm.endmodal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":[function($event){return _vm.get_scanlogs_pagination()},function($event){return _vm.$refs.dialog.save(_vm.todate)}]}},[_vm._v(" OK ")])],1)],1),_c('v-text-field',{staticClass:"mt-4 ml-2",staticStyle:{"max-width":"220px"},attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"gradient-bg white--text mt-n1",attrs:{"small":""},on:{"click":function($event){return _vm.open_data_correction_dialog()}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-table-edit")]),_vm._v("Add Data Correction ")],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v(" Add Data Correction ")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.dataCorrectionHeaders,"items":_vm.DataCorrectionItems,"dense":"","height":_vm.height,"loading":_vm.correctionLoading,"search":_vm.search,"loading-text":"Loading... Please wait","options":_vm.pagination,"fixed-header":_vm.fixed,"footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},on:{"update:options":[function($event){_vm.pagination=$event},function($event){return _vm.getVerifyPayPagination()}]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Data available. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.swipe_date",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow",domProps:{"textContent":_vm._s(_vm.get_date_only(item.swipe_time_stamp))}},on)),_c('br'),_c('span',_vm._g({staticClass:"overflow",domProps:{"textContent":_vm._s(_vm.get_time_only(item.swipe_time_stamp).split(',')[1])}},on)),_c('br')]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.get_date(item.swipe_time_stamp)))])])]}},{key:"item.user_name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(item.user_name))]),_c('br'),_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(item.employee_id))])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.user_name))])])]}},{key:"item.box_location",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.box_location.length >= 8 ? item.box_location.substr(0, 8) + "..." : item.box_location)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.box_location))])])]}},{key:"item.remarks",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.remarks.length >= 10 ? item.remarks.substr(0, 10) + "..." : item.remarks)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.remarks))])])]}},{key:"item.corrected_by",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(item.corrected_by))])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.corrected_by))])])]}},{key:"item.comment",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(item.comment))])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.comment))])])]}},{key:"item.swipe_data_corrected_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.swipe_data_corrected_on))}}),_c('br'),(item.acted_on == undefined)?_c('span',{staticClass:"ml-8"},[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.acted_on))}})]}},{key:"item.acted_on",fn:function(ref){
        var item = ref.item;
return [(item.acted_on == undefined)?_c('span',[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.acted_on))}})]}},{key:"item.approval_status",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'APPROVED'),expression:"item.approval_status == 'APPROVED'"}],staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.approval_status))]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'PENDING'),expression:"item.approval_status == 'PENDING'"}],staticClass:"ml-2",staticStyle:{"color":"orange"},attrs:{"small":"","depressed":"","mediumapproved_on":""},on:{"click":function($event){return _vm.Approve(item)}}},on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("PENDING ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'REJECTED'),expression:"item.approval_status == 'REJECTED'"}],staticClass:"ml-2",staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("REJECTED ")])])]}},{key:"item.approver_email_id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item.approver_email_id)?_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.approver_email_id.length >= 8 ? item.approver_email_id.substr(0, 8) + "..." : item.approver_email_id)+" ")]):_c('div',[_vm._v("-")])]}}],null,true)},[_c('div',{staticClass:"caption",staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(item.approver_email_id)+" ")])])]}}],null,true)})],1)],1),_c('AddDataCorrecion',{attrs:{"addDataCorrectionDialog":_vm.addDataCorrectionDialog},on:{"close_data_correction_dialog":function($event){_vm.addDataCorrectionDialog = false},"call_data_correction":_vm.call_dt}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),(_vm.component_check == 1)?_c('div',[_c('ApproveDatacorrection',{attrs:{"approvaldatacorrection":_vm.approvaldatacorrection,"approvaldta":_vm.approvaldta},on:{"clicked":_vm.close_prop,"errorMsg":_vm.errorPop,"successMsg":_vm.sucessPop}}),_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }