<template>
  <div>
    <v-dialog v-model="leavedetails" persistent width="600px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text"
            >Leave Details of {{ leaveitem.user_name }}</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :height="height"
            class="overflow-y-auto overflow mt-1"
            dense
            :headers="tabledata"
            :items="exporttable"
            :items-per-page="100"
            :fixed-header="fixed"
            :loading="isLoadingesa"
            :search="search"
            loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-options': [10, 15, 20, 25],
            }"
          >
            <template v-slot:[`item.leave_dates`]="{ item }">
              <div v-if="item.leave_dates != undefined">
                <pre>{{ formatDates(item.leave_dates, 3) }}</pre>
                <!-- <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-if="item.leave_dates" v-on="on">
                      {{
                        item.leave_dates.length >= 10
                          ? item.leave_dates.join(", ").substr(0, 30) + "..."
                          : item.leave_dates.join(", ")
                      }}
                    </div>
                    <div v-else>-</div>
                  </template>
                  <div class="caption white--text" style="max-width: 250px">
                    {{ item.leave_dates.join(", ") }}
                  </div>
                </v-tooltip> -->
              </div>
              <div v-else>-</div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    leavedetails: Boolean,
    leaveitem: Object,
  },
  data: () => ({
    search: "",
    exporttable: [],
    fixed: true,
    isLoadingesa: false,
    tabledata: [
      { text: "Leave Type", fixed: true, value: "leave_type" },
      {
        text: "Leave Count",
        value: "leave_count",
        fixed: true,
        sortable: false,
      },
      {
        text: "Leave Dates",
        value: "leave_dates",
        fixed: true,
        sortable: false,
      },
    ],
    height: 0,
  }),
  watch: {
    leavedetails: {
      handler() {
        this.height = window.innerHeight - 300;
        this.exporttable = this.leaveitem.leave_types;
        // console.log(this.leaveitem);
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    convertDateFormat(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    formatDates(dates, groupSize) {
      let formattedDates = [];
      // console.log(dates, "dates");
      dates = dates.map(this.convertDateFormat);

      for (let i = 0; i < dates.length; i += groupSize) {
        let groupedDates = dates.slice(i, i + groupSize).join(", ");

        formattedDates.push(groupedDates);
      }

      return formattedDates.join("\n");
    },
  },
};
</script>
  
  <style>
</style>